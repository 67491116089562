<template>
  <b-card>
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
        @submit="handleSubmit"
      >
        <b-row>
          <b-card
            title="Subscription Details"
            class="mb-0 w-100"
          >
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Tenant Type"
                  label-for="interval"
                >
                  <v-select
                    v-model="form.tenant_id"
                    :options="clientOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Tenant Type"
                    :disabled="readOnly"
                  >
                    <template
                      v-slot:option="option"
                      class="text-truncate"
                    >
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Package Name"
                  label-for="Package Name"
                >
                  <v-select
                    v-model="form.package_id"
                    :options="packageOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Package Name"
                    :disabled="readOnly"
                    deselect-from-dropdown
                  >
                    <!-- Template for displaying the package name -->
                    <template
                      v-slot:option="option"
                      class="text-truncate"
                    >
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-row>
        <b-row
          v-if="!readOnly"
        >
          <!-- submit -->
          <b-col class="d-flex justify-content-end">
            <b-button
              v-can="'business-profile-manage'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-1"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton, BCard, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')
const clientRepository = RepositoryFactory.get('client')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BCard,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      id: null,
      edit: false,
      readOnly: false,
      clientOptions: [],
      packageOptions: [],
      tenant_id: '',
      package_id: '',
      form: {
        data: {},
      },
    }
  },
  mounted() {
    this.getPackageNameList()
    this.getClientList()
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        const payload = {
          tenant_id: this.form.tenant_id,
          package_id: this.form.package_id,
        }
        const res = await SubscriptionRepository.createTenantSubscription(payload)
        if (res.status === 200 || res.status === 201) {
          this.showSuccessMessage('Subscribed Successfully')
          this.$router.push('/All-subscriptions')
        } else {
          this.showErrorMessage('Already subscribed to the Packge')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getPackageNameList() {
      try {
        const data = await SubscriptionRepository.getPackageListNoPagination()
        this.packageOptions = data.data.data.map(pkg => ({
          name: pkg.package_name,
          id: pkg.id,
        }))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getClientList() {
      try {
        const data = await clientRepository.getClientListNoPagination()
        this.clientOptions = data.data.data.map(client => ({
          name: client.name,
          id: client.id,
        }))

        return this.clientOptions
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
  },
}
</script>
